import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { colors } from '../../theme';
import * as S from './styles';

export type Props = {
  background?: keyof typeof colors;
  hasPadding?: boolean;
};

const Section: FC<Props> = ({ background = 'white', hasPadding = true, children }) => {
  const themeContext = useContext(ThemeContext);
  const backgroundValue = themeContext.colors[background];

  return (
    <S.Outer background={backgroundValue} hasPadding={hasPadding}>
      <S.Inner>{children}</S.Inner>
    </S.Outer>
  );
};

export default Section;
